import styled from '@emotion/styled';
import { breakpoints } from '$theme';

export const StyledAccordionList = styled.div({
    [breakpoints.md]: {
        display: 'grid',
        gap: 30,
        gridAutoFlow: 'row',
        alignItems: 'start',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateAreas: `
                'headline headline headline'
                '. accordion accordion'
            `,
        [breakpoints.md]: {
            gridTemplateAreas: `
                'headline accordion accordion'
            `,
        },
    },
});

export const StyledHeaderWrapper = styled.div(({ theme }) => ({
    marginBottom: theme.spaces[7],
    [breakpoints.md]: {
        gridArea: 'headline',
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'start',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: 'headline .',
        [breakpoints.md]: {
            gridTemplateColumns: '1fr',
            gridTemplateAreas: 'headline',
        },
    },
}));

export const StyledAccordionWrapper = styled.div({
    [breakpoints.md]: {
        gridArea: 'accordion',
    },
});

export const StyledAccordionDescription = styled.div(({ theme }) => ({
    marginBottom: theme.spaces[7],
}));

export const StyledActionWrapper = styled.div(({ theme }) => ({
    paddingTop: theme.spaces[4],
}));
